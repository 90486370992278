import {
  downloadingfiles,
  forcomp,
  getMethod,
  getMethod2,
  getMethod3,
  postMethod,
} from "./httpMethods";
// To populate options only
export const getCountries = () => {
  return new Promise((resolve, reject) => {
    getMethod("info/countrylist", "")
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const getRoles = () => {
  return new Promise((resolve, reject) => {
    getMethod("info/roles", "")
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const getLanguages = () => {
  return new Promise((resolve, reject) => {
    getMethod("info/languages", "")
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// To actually display on the page, some of them is also used to populate options
// School
export const getSchools = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("school", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const getSchool = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod2("school", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Organization
export const getOrganizations = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("organizations", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// User
export const getUsers = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("user", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// User Profile
export const getProfile = () => {
  return new Promise((resolve, reject) => {
    getMethod("user/profile", "")
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Participant
export const getParticipants = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("participant", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Domain and Tag
export const getDomains = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("tag", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Competition
export const getCompetitions = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("competition", params, signal)
      .then((d) => {
        resolve(d);
      })
      .catch((e) => reject(e));
  });
};
//get a single competition
export const getCompetition = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("competition", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const getCompetitionsPartnerPending = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("competition/partners/pending", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const getMarkingPreparation = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod2("marking/preparation/", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const getLevelCountries = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("info/levelCountyParticipantsList", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

export const getMoreInfo = (params, body, signal) => {
  console.log(body);
  return new Promise((resolve, reject) => {
    postMethod(`marking/participants/country/${params}`, body, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Competition Task
export const getDifficultyAndPoints = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("competition/difficultyandpoints/list", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Task
export const getTasks = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("tasks", params, signal)
      .then((d) => {
        resolve(d);
      })
      .catch((e) => reject(e));
  });
};

// Difficulty Group
export const getDifficultyGroup = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("taskdifficultygroup", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
// Collection
export const getCollections = (params, signal) => {
  return new Promise((resolve, reject) => {
    console.log(params);
    getMethod("collection", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
//marking overview
export const getMarking = (params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod("marking", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
//
export const getCompCountries = (params, id, signal) => {
  return new Promise((resolve, reject) => {
    console.log(`competition/${id}/countries${params}`);
    getMethod2(`competition/${id}/countries${params}`, "", signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
//editRoundmarking
export const getEditRound = (params, signal) => {
  console.log(params);
  return new Promise((resolve, reject) => {
    getMethod3("marking/moderate", params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//generate report
export const getReport = (id,params, signal) => {
  return new Promise((resolve, reject) => {
    console.log(signal);
    getMethod3(`competition/${id}/report?mode=csv`, params,signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
//generate report modrate results
export const getModerateReport = (id, params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod3(`competition/${id}/report`, params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const getTask = (id) => getMethod("task", id);
export const getDifficulties = () => getMethod("difficulty", "");
export const getUser = (id) =>
  getMethod("user", id).then((data) => ({
    id: data.id,
    username: data.username,
    role: data.role,
    permissionOverridden: true,
    email: data.email,
    country: data.country,
    createdBy: data.createdBy,
    lastModifiedBy: data.lastModifiedBy,
    lastLogin: data.lastLogin,
    status: data.status,
    password: data.password,
  }));
export const getTranslations = () => {
  return new Promise((resolve, reject) => {
    getMethod("task", "").then((data) => {
      if (data.length) {
        let a = Object.getOwnPropertyNames(data[0]).slice();
        let temp = a[3];
        a[3] = a[4];
        a[4] = temp;
        resolve([
          a.filter(
            (b) =>
              ![
                "lastVerified",
                "createdBy",
                "displayOrder",
                "answers",
                "answerStructure",
                "collections",
                "competitions",
                "recommendedDifficulty",
                "tags",
              ].includes(b)
          ),
          data,
        ]);
      } else {
      }
    });
  });
};
//get partcipient report
export const getParticipantsReport = (index, cert, params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod(
      `participant/report/by-certificate?index_no=${index}&certificate_no=${cert}`,
      params,
      signal
    )
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//get awardStat status
export const getAwardStatStatus = (id, params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod2(`marking/competition/groups/award_statics/${id}`, params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const getGroudStats = (id, params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod2(`marking/awards/stats/${id}`, params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//get competition collection
export const getCompCollection = (id, signal) => {
  return new Promise((resolve, reject) => {
    getMethod(
      `collection/difficultyandpoints/overview?competition_id=${id}`,
      signal
    )
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//get Cheaters status
export const getCheaters = (id, params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod2(`competition/compute/cheaters/${id}`, params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const downloadCheater = (id, fileName, country) => {
  return new Promise((resolve, reject) => {
    downloadingfiles(
      country.length == 0
        ? `competition/compute/cheaters/${id}?mode=csv`
        : `competition/compute/cheaters/${id}?country=[${country}]&&mode=csv`,
      fileName
    )
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
export const getCountries2 = (params) => {
  return new Promise((resolve, reject) => {
    getMethod("info/countrylist", params)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
//get level tasks
export const getLevelTasks = (competitionId, levelId, signal) => {
  console.log(signal);
  return new Promise((resolve, reject) => {
    getMethod(
      `marking/possible_similar_answers/levels_tasks/${competitionId}?level_id=${levelId}`,
      "",
      signal
    )
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

export const getSameParticipants = (id, params, signal) => {
  return new Promise((resolve, reject) => {
    getMethod2(`competition/cheaters/same-participant/${id}`, params, signal)
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

export const getConfirmedCountries = (id) => {
  return new Promise((resolve, reject) => {
    getMethod2(`competition/cheaters/confirmed-countries/${id}`, "")
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};
//get task possible answers
export const getTaskPossibleAnswers = (taskId, levelId, signal) => {
  return new Promise((resolve, reject) => {
    getMethod(
      `marking/possible_similar_answers/${taskId}?level_id=${levelId}`,
      "",
      signal
    )
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//get answer participants
export const getAnswerParticipants = (answerId, signal) => {
  return new Promise((resolve, reject) => {
    getMethod(
      `marking/possible_similar_answers/participants/${answerId}`,
      "",
      signal
    )
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};

//get competition levels
export const getCompetitionLevels = (competitionId, signal) => {
  console.log(signal);
  return new Promise((resolve, reject) => {
    getMethod(
      `marking/possible_similar_answers/competition_levels/${competitionId}`,
      "",
      signal
    )
      .then((d) => resolve(d))
      .catch((e) => reject(e));
  });
};


export const getParticipantData = (id) =>{
  return new Promise((resolve , reject)=>{
    getMethod2(`info/participant/${id}`,'')
    .then((d) => resolve(d))
    .catch((e) => reject(e));
  })
}

export const getMarkedParticipants = (id , signal) =>{
  return new Promise((resolve , reject)=>{
    getMethod2(`competition/cheaters/custom-labeled-integrity-cases/${id}`,'', signal)
    .then((d) => resolve(d))
    .catch((e) => reject(e));
  })
}

export const getIntegrityListStats = (id , signal) =>{
  return new Promise((resolve , reject)=>{
    getMethod2(`competition/cheaters/criteria-stats/${id}`,'', signal)
    .then((d) => resolve(d))
    .catch((e) => reject(e));
  })
}

export const CountryIntegrityData = (id , countryId,signal) =>{
  return new Promise((resolve , reject)=>{
    getMethod2(`competition/cheaters/${id}/${countryId}`,'', signal)
    .then((d) => resolve(d))
    .catch((e) => reject(e));
  })
}

